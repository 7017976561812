.lightsaber-container {
    height: .7rem;
    display: flex;
    align-items: center;
}

.lightsaberx {
    height: inherit;
    position: relative;
    display: flex;
    align-items: center;
}

/* Blue Blade */
.b-blade {
    position: absolute;
    top: 0;
    left: 50px;
    width: 0;
    /* Blade starts hidden */
    height: 10px;
    background: linear-gradient(to right, #327aff 0%, rgba(74, 137, 255, 0.829) 100%);
    border-radius: 5px;
    transition: width 0.2s ease, opacity 0.1s ease, box-shadow 0.2s ease;
    transform-origin: left;
    z-index: -1;
    animation: none;
}

.b-blade.on {
    width: 250px;
    /* Full extension */
    box-shadow: 0 0 20px #327aff, 0 0 40px rgba(15, 98, 254, 1), 0 0 80px rgba(15, 98, 254, 1);
    z-index: 5;
    /* Subtle flicker effect */
    animation: flicker 2s infinite alternate ease-in-out;
}

.b-blade.off {
    width: 200;
    opacity: 0;
    box-shadow: 0 0 5px #327aff, 0 0 10px rgba(15, 98, 254, 0.5), 0 0 20px rgba(15, 98, 254, 0.5);
    z-index: 5;
    animation: flicker .2s infinite ease-in-out;
}


/* Red Blade */
.r-blade {
    position: absolute;
    top: 0;
    left: 50px;
    width: 0;
    /* Blade starts hidden */
    height: 10px;
    background: linear-gradient(to right, #ff3232 0%, rgba(255, 74, 74, 0.829) 100%);
    border-radius: 5px;
    transition: width 0.2s ease, opacity 0.1s ease, box-shadow 0.2s ease;
    transform-origin: left;
    z-index: -1;
    animation: none;
}

.r-blade.on {
    width: 250px;
    /* Full extension */
    box-shadow: 0 0 20px #ff3232, 0 0 40px rgb(254, 15, 15), 0 0 80px rgb(254, 15, 15);
    z-index: 5;
    /* Subtle flicker effect */
    animation: flicker-red 2s infinite alternate ease-in-out;
}

.r-blade.off {
    width: 200;
    opacity: 0;
    box-shadow: 0 0 5px #ff3232, 0 0 10px rgba(254, 15, 15, 0.5), 0 0 20px rgba(254, 15, 15, 0.5);
    z-index: 5;
    animation: flicker-red .2s infinite ease-in-out;
}

/* Subtle flicker effect for both opacity and box-shadow intensity */
@keyframes flicker {
    0% {
        opacity: 1;
        box-shadow: 0 0 20px #327aff, 0 0 40px rgba(15, 98, 254, 0.9), 0 0 80px rgba(15, 98, 254, 0.9);
    }

    25% {
        opacity: 0.98;
        box-shadow: 0 0 19px #327aff, 0 0 38px rgba(15, 98, 254, 0.8), 0 0 75px rgba(15, 98, 254, 0.8);
    }

    50% {
        opacity: 0.97;
        box-shadow: 0 0 21px #327aff, 0 0 42px rgba(15, 98, 254, 1), 0 0 85px rgba(15, 98, 254, 1);
    }

    75% {
        opacity: 0.99;
        box-shadow: 0 0 18px #327aff, 0 0 35px rgba(15, 98, 254, 0.75), 0 0 70px rgba(15, 98, 254, 0.75);
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 20px #327aff, 0 0 40px rgba(15, 98, 254, 0.9), 0 0 80px rgba(15, 98, 254, 0.9);
    }
}

@keyframes flicker-red {
    0% {
        opacity: 1;
        box-shadow: 0 0 20px #ff3232, 0 0 40px rgba(254, 15, 15, 0.9), 0 0 80px rgba(254, 15, 15, 0.9);
    }

    25% {
        opacity: 0.98;
        box-shadow: 0 0 19px #ff3232, 0 0 38px rgba(254, 15, 15, 0.8), 0 0 75px rgba(254, 15, 15, 0.8);
    }

    50% {
        opacity: 0.97;
        box-shadow: 0 0 21px #ff3232, 0 0 42px rgb(254, 15, 15), 0 0 85px rgb(254, 15, 15);
    }

    75% {
        opacity: 0.99;
        box-shadow: 0 0 18px #ff3232, 0 0 35px rgba(254, 15, 15, 0.75), 0 0 70px rgba(254, 15, 15, 0.75);
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 20px #ff3232, 0 0 40px rgba(254, 15, 15, 0.9), 0 0 80px rgba(254, 15, 15, 0.9);
    }
}

.lightsaberx img:hover {
    filter: drop-shadow(0 0 10px #dbe7ff9a);
    cursor: pointer;
    transition: .2s ease-in-out;
}

.lightsaberx img {
    transition: .2s ease-in-out;
}
